<template>
  <v-container id="dashboard" fluid tag="section">
    <v-form ref="form">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-earth"> 
            <slot slot="title">Your Profile</slot>
            <slot slot="rightButtons">
              <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
            </slot>
            <v-row>
              <v-col class="mt-4" >
                <div v-if="showEditToggle">
                  <text-field label="Email" v-model="editUser.email" :rules="[utils.emailRule]">
                    <slot slot="tooltip">Your email for general correspondance.  You can set a seperate email for each box office and event if you like.
                    </slot> 
                  </text-field>
                  <text-field label="Name" v-model="editUser.name" :rules="[utils.requiredRule]">
                    <slot slot="tooltip">Your email for general correspondance.  You can set a seperate email for each box office and event if you like.
                    </slot> 
                  </text-field>
                  <text-field label="Phone" v-model="editUser.phone">
                    <slot slot="tooltip">Your phone number for general correspondance.  This will not be communicated to your customers.
                    </slot> 
                  </text-field>
                  <v-row class="mt-5">
                    <v-col cols="auto">
                      <v-spacer/>
                      <v-btn color="success" @click="update">Save</v-btn>
                      <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
                    </v-col>
                  </v-row>
                </div>
              <div v-else>
                <v-row>
                  <v-col cols="2">Email</v-col>
                  <v-col cols="10">{{editUser.email}}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">Name</v-col>
                  <v-col cols="10">{{editUser.name}}</v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">Phone</v-col>
                  <v-col cols="10">{{editUser.phone}}</v-col>
                </v-row>
              </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  export default {
    name: 'Profile',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      },
      user () {
        return this.$store.state.auth.userAuth
      }
    },

    data () {
      return {
        editUser: {},
        showEditToggle: false,
      }
    },

    created() {
      this.load();
    },
    
    methods: {
      
      async load() {
        if (this.user) { 
          this.editUser = Utils.jsonCopy(this.user);
        } else {
          this.editUser = {};
          this.editUser.email = this.$auth.user.email;
          this.editUser.name = this.$auth.user.name;
        }
      },

      async update() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        if (this.user) {
          this.user = await Api.post(this, "User", "update", this.editUser);
        } else {
          this.user = await Api.post(this, "User", "create", this.editUser);
        }
        this.editUser = Utils.jsonCopy(this.user);
        this.showEditToggle = false;
      },

      showEdit(show) {
        this.showEditToggle = show;
      },
    }
  }

</script>
